// Vuetify
import "../scss/_variables.scss";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, fa } from 'vuetify/iconsets/fa';

const seaFishAdmin = {
    dark: false,
    colors: {
        primary: "#006FBB",
        primaryHighlight: "#00A3E0",
        backgroundBlue: "#EEF5FA",
        backgroundGrey: "#E3E3E3",
        navy: "#002D4D",
        darkGrey: "#363A3D",
        lightGrey: "#696C6E",
        text: "#1B1E23",
        background: "#EDF2F8",
        secondary: "#256A67",
        secondaryshade: "#43908C",
        accent: "#82B1FF",
        error: "#B00020",
        errorSecondary: "#F5E0E4",
        info: "#2196F3",
        success: "#4CAF50",
        pass: "#3A863D",
        fail: "#FF0000",
        warning: "#FFC107",
        white: "#FFFFFF",
        apprenticeship: '#694523',
        certificateOfCompetence: '#4E6CC1',
        engineroom: '#DB2391',
        fireHealthAndSafety: '#ED0A0A',
        foodSafety: '#864BD1',
        introductionToCommercialFishing: '#143F80',
        medical: '#006F87',
        navigationAndRadio: '#837810',
        nvq: '#B3611D',
        seaSafety: '#4B7E0B',
        svq: '#138942',
        seafishEvents: '#0C24FF',
        danger: '#B00020',
        extra2: '#B000B4',
        extra3: '#873100',
        extra4: '#870000',
        extra5: '#008A1E',
        extra6: '#363A3D',
        extra7: '#143409',
        extra8: '#767676',
        extra9: '#0A0A0A',
        leftMenuBorder: '#12496F',
        veryLightGrey: '#B7B7B7',
        pagination: '#dde9f2'
    },
    variables: {
        'medium-emphasis-opacity': 1,
    },
};

const vuetify = createVuetify({
    components: {
        ...components,
    },
    directives,
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    },
    theme: {
        defaultTheme: 'seaFishAdmin',
        themes: {
            seaFishAdmin,
        },
    },
});

export default vuetify;
